<template>
  <div class="page flex-col">
    <header>
      <van-nav-bar
        title="确认订单"
        left-text=""
        left-arrow
        @click-left="onClickLeft"
      />
    </header>
    <section>
      <van-tabs v-model="active" sticky >
        <van-tab :style="{paddingTop:'1rem'}" v-for="(item, index) in tabList" :title="item.title" :key="index" class="pickUp">
          <!-- 分割线 -->
          <template v-if="active == 0">
            <van-divider
              :style="{ color: '#efefef', borderColor: '#efefef', padding: '0 16px',backgroundColor: '#efefef' }">
              分割线
            </van-divider>

            <van-nav-bar class="ownNavBar" title="" v-bind:left-text="text"  @click-right="onRightLeft">
              <template #right>
                <van-icon name="arrow" size="18"/>
              </template>

            </van-nav-bar>
            <!-- 分割线 -->
            <van-divider
              :style="{ color: '#efefef', borderColor: '#efefef', padding: '0 16px',backgroundColor: '#efefef' }">
              分割线
            </van-divider>

            <div class="orderMain">
              <!-- <div class="goods"> -->
                <div class="good" >
                  <img :src="goodsList.smallImagePath" alt="">
                  <div class="goodinfo">
                    <div class="goodname">
                      <!--<span>自营</span>-->
                      <span>{{goodsList.productName}}</span>
                    </div>
                    <div class="shipping">
                      <span>包邮</span>
                    </div>
                    <div class="price">
                      <span>￥</span>
                      <span>{{goodsList.productPrice}}</span>
                    </div>
                    <div class="curStores">
                      <span>{{goodsList.shopName}}</span>
                    </div>
                  </div>
                </div>
              <!-- </div> -->
            </div>
          </template>
          <!-- 到店自取 -->
          <template v-else>
            <div class="demo" v-for="index in 8" :key="index">
              到店自取{{ index }}
            </div>
          </template>
        </van-tab>
      </van-tabs>
    </section>
    <footer class="confirmOrder">
      <div class="allPrice">
        <span>¥</span>
        <span>30.59</span>
      </div>
      <van-button :style="{width: '8.3rem',height: '2.56rem',fontSize: '1rem'}" round color="#fb743a">立即支付</van-button>
    </footer>
  </div>
</template>
<script>
  import {queryOrderAddressByMemberId} from "@/api/user";
  import {queryProductById} from "@/api/order";
  import axios from 'axios';
export default {
  data() {
    return {
      active:0,
      tabList:[
        {title:'快递配送',},
    /*    {title:'到店自取',},*/
      ],
      text: '请填写收货地址',
      goodsList:{},
      productId:'',
      memberId: '',
      memberAccount:'',
      memberLevel:'',
      constants:{},
      baseURL:axios.defaults.baseURL,
    };
  },
  created(){
    const user = localStorage.getItem('userInfoDs')
    if(!user){
      this.$router.push('/login')
      return
    }
    this.memberId = JSON.parse(user).memberId
    this.memberAccount = JSON.parse(user).account
    this.memberLevel = JSON.parse(user).level
  },
  mounted(){
    this.productId = this.$route.query.productId;
    queryOrderAddressByMemberId(this.memberId).then(response => {
      if (response.code == 200) {
        response.data.forEach((item) => {
          if(item.isDefault == 0){
            this.constants = item
            this.text = item.address+"("+item.name+item.phone+")"
          }
        })
      }
    })
    queryProductById(this.productId).then(response => {
      if(response.code==200){
        this.goodsList = response.data;
        this.goodsList.smallImagePath = this.baseURL+response.data.smallImagePath;
      }
    })
  },
  methods: {
    // 返回
    onClickLeft(){
      this.$router.push({ name: 'mallInfo', query: { productId: this.productId }});
    },
    onRightLeft(){
      this.$router.push('/userAddress')
    }
  }
};
</script>
<style scoped lang="css">
  * {
    padding: 0;
    margin: 0;
  }
  html,
  body {
    font-size: 37.5px;
    height: 100%;
  }
  .page{
    height: 100vh;
    display: flex;
  }
header{
  height: 3.36rem;
  background-color: pink;
}
footer{
  height: 4rem;
  border-top: 1px solid #ededed;
}
section{
  flex: 1;
  overflow: auto;
}
.ownNavBar{
  padding: 1.6rem 0;
}
.ownNavBar.van-nav-bar .van-icon{
  font-size: 1rem;
  color: #4d4a4a;
}
.ownNavBar ::v-deep .van-nav-bar__text{
  height: 1.21rem;
  color: #999;
}
.orderMain{
  padding: 0.4rem 1.33rem;
}
.confirmOrder{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1.33rem;
}
.allPrice span:nth-child(1){
  font-size: 1rem;
  color: #d81e06;
}
.allPrice span:nth-child(2){
  font-size: 1.33rem;
  color: #d81e06;
}
/* copy */
.good{
  display: flex;
  padding: 1.2rem 0;
  border-bottom: 1px solid #f3f3f3;
}
.good img{
  width: 8rem;
  height: 5.867rem;
  border-radius: 20px;
  margin-right: 20px;
}
.goodinfo{
  display: flex;
  flex-direction: column;
}
.goodname{
  padding: 0.21rem 0;
  display: flex;
}
.goodname span:nth-child(1){
  display: block;
  padding: 3px 0.32rem;
  font-size: 0.693rem;
  line-height: 0.9rem;
  color:#fff;
  background-color: #fc7a3d;
  border-radius: 3px;
  margin-right: 0.48rem;
}
.goodname span:nth-child(2){
  font-size: 0.8rem;
  color: #333;

}

.shipping span{
  display: inline-block;
  font-size: 0.64rem;
  padding: 0.13rem 0.32rem;
  color: #999;
  line-height: 0.9rem;
  margin: 0.25rem 0;
  border: 1px solid #c7c6c6;
  border-radius: 0.9rem ;
  box-sizing: border-box;
}
.price span{
  display: inline-block;
  color:#d81e06;
  font-weight: bold;
}
.price span:nth-child(1){
  font-size: 0.69rem;
}
.price span:nth-child(2){
  font-size: 1rem;
}
.curStores span {
  display: inline-block;
  line-height: 1.01rem;
  padding: 0.21rem 0.53rem;
  font-size: 0.64rem; 
  color:#bdbdbd;
  border-radius: 1.01rem;
  background-color: #f5f5f5;
}
/* 粘性布局 */
.van-sticky{
  top: 3.36rem
}
::v-deep .van-sticky--fixed{
  top: 3.36rem
}
.demo{
  height: 10rem;
  margin: 0.5rem 0;
  background-color: skyblue;
}
</style>